@import "swiper/swiper-bundle.css";
@import "swiper/scss";
@import "swiper/scss/pagination";
@import "swiper/scss/navigation";

.hide-scrollbar{
	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar{
		display: none;
	}
}

.bg-gradient-nomads {
	background: #5C27E8 linear-gradient(95.7deg, #6C4BA2 0%, #7866EA 100%);
}

.antialiased{
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.loader{
	border-top-color: #8FD200;
	-webkit-animation: spinner 1.5s linear infinite;
	animation: spinner 1.5s linear infinite;
}

.container{
	padding-left: 0 !important;
	padding-right: 0 !important;
}

@-webkit-keyframes spinner{
	0%{ -webkit-transform: rotate(0deg); }
	100%{ -webkit-transform: rotate(360deg); }
}

@keyframes spinner{
	0%{ transform: rotate(0deg); }
	100%{ transform: rotate(360deg); }
}

.menu-button {
	width: 20px;
	height: 18px;
	position: relative;
}

.menu-button:hover {
	cursor: pointer;
}

.strip {
	transition: 0.5s;
	width: 100%;
	height: 2px;
	background-color: #171227;
	position: absolute;
	border-radius: 10px;
}

.strip1 {
	top: 0;
}

.strip2 {
	top: 8px;
	left: 0;
}

.strip3 {
	bottom: 0;
}

.change .strip1 {
	top: 8px;
	height: 2px;
	transform: rotate(45deg);
}

.change .strip2 {
	background-color: rgba(0, 0, 0, 0%);
	width: 0;
	height: 0;
	top: 50%;
	left: 25%;
}

.change .strip3 {
	height: 2px;
	top: 8px;
	transform: rotate(-45deg);
}

.h-grow {
	height: calc(100vh - 56px);
}
